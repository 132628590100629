import React, { FormEvent } from 'react';
import FunctionsClient from '../../lib/functions-client'
import styles from '../login/component.module.scss'
import LoadingButton from "../../components/loading-button/loading-button";
import { Content, SubHeader } from '../../layout/default'
import Card from '../../components/card/card';


class UnblockPage extends React.Component<any, any> {
    customerNumber: React.RefObject<any>;

    constructor(props: any) {
        super(props);

        this.customerNumber = React.createRef();
        this.state = {
            isSending: false,
            hasError: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (
            this.state.isSending ||
            !this.customerNumber.current ||
            this.customerNumber.current.value.length === 0
        ) {
            return;
        }

        this.setState({
            isSending: true,
            hasError: false,
        });
        FunctionsClient.unblockIban(this.customerNumber.current.value)
            .then(result => {
                this.setState({
                    isSending: false,
                    hasError: !result.status
                });
            }).catch(error => {
                this.setState({
                    isSending: false,
                });

                alert('Fehler: ' + error);
            });

    }

    render() {
        return (<>
            <SubHeader title='Bankkonto entsperren' />
            <Content>
                <form onSubmit={this.onSubmit} className={`${styles.form} ${this.state.hasError ? styles.failed : ''}`}>
                    <div className="form-group">
                        <label className={'font-size-h6 font-weight-bolder text-dark'} htmlFor="email">Kundennummer</label>
                        <input ref={this.customerNumber} className="form-control form-control-solid h-auto py-7 px-6 rounded-lg" id="unblockiban-cn" />
                    </div>
                    <div className={'pb-lg-0 pb-5 text-center'}>
                        <LoadingButton isLoading={this.state.isSending} type={'submit'} className={'font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3'}>Bankkonto entsperren</LoadingButton>
                    </div>
                </form>
            </Content>
        </>);
    }

}

export default UnblockPage;