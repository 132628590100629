import React from 'react'

import {currencyFormat, dateShortFormat, numberFormat, valueFormat} from "../../../../lib/formater";

import {BalanceCallback, BalanceType, IProps} from "./props";

import {IStatemodel} from './statemodel';
import {IBalanceItemViewmodel} from './viewmodel/item';

import styles from './component.module.scss';

class ProductBalance extends React.Component<IProps, IStatemodel> {

  allItems: IBalanceItemViewmodel[];
  onChange: BalanceCallback;

  constructor(props: IProps) {
    super(props);

    this.onChange = props.onChange || ((type: BalanceType, items: string[]) => items);

    this.allItems = props.items as IBalanceItemViewmodel[];

    this.state = {
      items: this.allItems,
      selectedIds: [],
      activeUnSelectedIds: [],
      activeSelectedIds: [],
    };

    this.onSelectAllClick = this.onSelectAllClick.bind(this);
    this.onDeSelectAllClick = this.onDeSelectAllClick.bind(this);
    this.onSelectSome = this.onSelectSome.bind(this);
    this.onDeSelectSome = this.onDeSelectSome.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  updateUnavailableIds(unavailableIds: string[]): string[] {
    const items = this.allItems.filter(item => !unavailableIds.includes(item.id));
    const selectedIds = this.state.selectedIds.filter(id => !unavailableIds.includes(id));

    this.setState({
      items,
      selectedIds,
    });

    return selectedIds;
  }

  onSelectAllClick() {
    const selectedIds = this.state.items.map(item => item.id);

    this.setState({
      selectedIds: this.onChange(this.props.type, selectedIds),
      activeUnSelectedIds: [],
      activeSelectedIds: [],
    });
  }

  onDeSelectAllClick() {
    this.setState({
      selectedIds: this.onChange(this.props.type, []),
      activeUnSelectedIds: [],
      activeSelectedIds: [],
    });
  }

  onSelectSome() {
    // @ts-ignore
    const selectedIds = [...new Set(
        this.state.selectedIds
            .concat(...this.state.activeUnSelectedIds)
    )];

    this.setState({
      selectedIds: this.onChange(this.props.type, selectedIds),
      activeUnSelectedIds: [],
    });
  }

  onDeSelectSome() {
    const selectedIds = this.state.selectedIds.filter(id => !this.state.activeSelectedIds.includes(id));

    this.setState({
      selectedIds: this.onChange(this.props.type, selectedIds),
      activeSelectedIds: [],
    });
  }

  onItemClick(id: string) {
    let key;
    let value = [];

    //
    // tslint:disable-next-line:prefer-conditional-expression
    if (this.state.selectedIds.includes(id)) {
      key = 'activeSelectedIds';
    } else {
      key = 'activeUnSelectedIds';
    }

    if (!key) {
      return;
    }

    // @ts-ignore
    value = this.state[key];

    //
    // @ts-ignore
    if (this.state[key].includes(id)) {
      // remove
      value = value.filter((v: string) => v !== id);
    } else {
      // add
      value.push(id);
    }

    //
    const state = {};
    // @ts-ignore
    state[key] = value;
    this.setState(state);
  }

  unSelectedIds() {
    return this.state.items
        .filter(item => !this.state.selectedIds.includes(item.id))
        .map(item => item.id);
  }

  renderItems(itemIds: string[]) {
    let items = this.state.items;

    if (itemIds) {
      items = this.state.items.filter(item => {
        return itemIds.includes(item.id);
      });
    }

    items.sort((a, b) => {
      if (a.out && b.out && this.props.type) {
        if (a.out.profit > b.out.profit) return -1;
        if (a.out.profit < b.out.profit) return 1;
        return 0;
      }

      if (a.value > b.value) return -1;
      if (a.value < b.value) return 1;
      return 0;
    });

    return (
        <ol className={`form-control ${styles.items} ${items.length === 0 ? styles.empty : ''} ${this.props.className || ''}`}>
          {items.map((item, i) => {
            let outNetFormat = {
              className: '',
              value: '',
            };

            if (item.out) {
              outNetFormat = valueFormat({
                unit: 'currency',
                value: item.out.profit,
              });
            }

            return (
              <li key={i} className={`${styles.item} ${this.state.activeUnSelectedIds.includes(item.id) || this.state.activeSelectedIds.includes(item.id) ? styles.active : ''}`}>
                {/* tslint:disable-next-line:jsx-no-lambda */}
                <button onClick={() => this.onItemClick(item.id)} className={'row'}>
                  <span className={`col-2 ${styles.name}`}>{numberFormat(item.value)} {item.unit}</span>
                  <span className={`col-10 ${styles.details}`}>
                    <span className={`row ${styles.in}`}>
                      <span className={'col-5'}>
                        {currencyFormat(item.in.pricePerKg)}/Kg
                      </span>
                      <span className={'col-4'}>
                        {currencyFormat(item.in.price)}
                      </span>
                      <span className={'col-3'}>
                        {dateShortFormat(item.in.date)}
                      </span>
                    </span>
                    {item.out &&
                      <span className={`row ${styles.out}`}>
                        <span className={'col-5'}>
                          {currencyFormat(item.out.pricePerKg)}/Kg
                        </span>
                        <span className={'col-4'}>
                          {currencyFormat(item.out.price)}
                        </span>
                        <span className={`col-3 ${outNetFormat.className}`}>
                          {outNetFormat.value}
                        </span>
                      </span>
                    }
                  </span>
                </button>
              </li>
            );
          })}
        </ol>
    );
  }

  render() {
    return (
        <div className={`${styles.balance} ${this.props.className || ''}`} data-type={this.props.type}>

          <div className={`${styles.select}`}>
            {this.props.labelLeft &&
              <div className={`form-control ${styles.header}`}>{this.props.labelLeft}</div>
            }

            {this.renderItems(
                this.state.selectedIds
            )}
          </div>

          <div className={`${styles.controls}`}>
            <button disabled={this.unSelectedIds().length === 0} onClick={this.onSelectAllClick} className={`${styles.first} btn btn-secondary`} />
            <button disabled={this.state.activeUnSelectedIds.length === 0} onClick={this.onSelectSome} className={`${styles.prev} btn btn-secondary`} />
            <button disabled={this.state.activeSelectedIds.length === 0} onClick={this.onDeSelectSome} className={`${styles.next} btn btn-secondary`} />
            <button disabled={this.state.selectedIds.length === 0} onClick={this.onDeSelectAllClick} className={`${styles.last} btn btn-secondary`} />
          </div>

          <div className={`${styles.select}`}>
            {this.props.labelRight &&
              <div className={`form-control ${styles.header}`}>{this.props.labelRight}</div>
            }

            {this.renderItems(
                this.unSelectedIds()
            )}
          </div>

        </div>
    );
  }
}

export default ProductBalance
