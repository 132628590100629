import Storage from '../storage'
import FunctionsClientUnauthorizedError from "./error/unauthorized";

interface IResponseData {
  status: boolean
  data: any
}

class FunctionsClient {

  _request(
    resource: string,
    method = 'get',
    payload: any = null,
    auth = true,
  ): Promise<any | null> {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    if (auth) {
      const token = Storage.getToken();
      if (token !== null) {
        headers.append('Authorization', 'Bearer ' + token);
      }
    }

    return fetch(process.env.REACT_APP_FUNCTIONS_BASEURL + resource, {
      method,
      headers,
      body: payload === null ? null : JSON.stringify(payload),
    })
      .then(response => {
        if (response.status === 401 && auth) {
          throw new FunctionsClientUnauthorizedError();
        }
        return response;
      })
      .then(response => {
        if (response.status === 201) {
          return {
            status: true,
            data: null,
          };
        }

        if (response.status >= 400) {
          throw new Error('request failed');

        }

        return response.json();
      })
      .then((response: IResponseData) => {
        if (!response.status) {
          return null;
        }

        return response.data;
      })
      .catch(e => {
        if (e instanceof FunctionsClientUnauthorizedError) {
          Storage.clear();
          window.location.reload();
          return;
        }

        throw e;
      });
  }

  login(username: string, password: string): Promise<boolean> {
    return this._request(
      'backendLogin',
      'post',
      {
        username,
        password,
      },
      false
    ).then(data => {
      if (data === null) {
        return false;
      }

      if (!data.token || !data.profile) {
        throw new Error('invalid response');
      }

      Storage.setToken(data.token);
      Storage.setProfile(data.profile);

      return true;
    });
  }

  orders(): Promise<any | null> {
    return this._request('backendOrders');
  }

  placeProviderOrder(data: any): Promise<void> {
    return this._request(
      'submitOrderData',
      'post',
      data
    );
  }

  unblockIban(customerNumber: string) {
    return this._request('unblockIban', 'post', { 'cn': customerNumber })
  }

  validateBanking(data: any): Promise<any | null> {
    return this._request('backendvalidateBanking', 'post', data);
  }

  //show sell Requests which are not booked
  getSellRequests(): Promise<any | null> {
    return this._request('backendSellRequests');
  }

  bookSellRequests(data: any): Promise<any | null> {
    return this._request('backendBookSellRequests', 'post', data);
  }

}

export default new FunctionsClient()
