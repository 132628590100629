import moment from 'moment'

const numberFormater = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
export const numberFormat = (value: number) => numberFormater.format(value);

export const currencyFormat = (value: number, symbol = '€') => numberFormater.format(value) + ' ' + symbol;

export const dateFormat = (value: moment.MomentInput) => {
  return moment(value).format('DD.MM.YYYY');
}

export const dateShortFormat = (value: moment.MomentInput) => {
  return moment(value).format('DD.MM.YY');
}

export const timeFormat = (value: moment.MomentInput) => {
  return moment(value).format('HH:mm');
}

export type ValueUnit = 'currency' | 'currency-raw' | 'date';

interface IValueData {
  value?: any
  unit?: ValueUnit
}

interface IValueFormat {
  value: string
  className: string
}

export const valueFormat = (data: IValueData): IValueFormat => {
  const r: IValueFormat = {
    value: '',
    className: '',
  };

  if (data.value === undefined) {
    return r;
  }

  switch(data.unit || '') {
    case "currency":
      r.value = (data.value > 0 ? '+' : '') + currencyFormat(data.value);
      r.className = (data.value > 0) ? 'positive' : (data.value < 0) ? 'negative' : '';
      break;
    case "currency-raw":
      r.value = currencyFormat(data.value);
      break;
    case "date":
      r.value = dateFormat(data.value);
      break;
    default:
      // tslint:disable-next-line:prefer-conditional-expression
      if (typeof data.value === 'number') {
        r.value = numberFormat(data.value);
      } else {
        r.value = data.value;
      }

      if (data.unit && data.unit.length > 0) {
        r.value += ' ' + data.unit;
      }
  }

  return r;
}
