import moment from "moment";
import {
  IResponseInventoryItem,
  IResponseOrder,
  IResponseMetal,
  IResponsePrice
} from "../../../../../functions/src/backend/routes/orders/model/orders_view";

export type IOrdersProductViewmodel = IResponseMetal;
export type IOrderProviderPriceViewmodel = IResponsePrice;

export interface IOrdersOrderEntryViewmodel {
  id: string
  date: Date
  amountInGramm: number
  pricePerKg: number
  total: number
  margin: number
  sellToCustomerPricePerKg: number
}

export interface IOrdersOrderViewmodel {
  product: IOrdersProductViewmodel
  orders: IOrdersOrderEntryViewmodel[]
  amountInGramm: number
  averagePricePerKg: number
  margin: number
}

export type IOrdersInventoryItemViewmodel = IResponseInventoryItem;

export function OrderViewModel(order: IResponseOrder, products: IOrdersProductViewmodel[]): IOrdersOrderViewmodel {
  const orderEntries = order.entries.map(e => {
    return {
      id: e.id,
      date: moment(e.date).toDate(),
      amountInGramm: e.amountInGramm,
      pricePerKg: e.pricePerKg,
      total: e.total,
      margin: e.margin,
      sellToCustomerPricePerKg: e.sellToCustomerPricePerKg
    };
  });

  return {
    product: products.find(p => p.id === order.metalId)!,
    orders: orderEntries,
    amountInGramm: order.entries.reduce((carry, entry) => carry + entry.amountInGramm, 0),
    averagePricePerKg: order.entries.length > 0 ? order.entries.reduce((carry, entry) => carry + entry.pricePerKg, 0) / order.entries.length : 0,
    margin: orderEntries.reduce((carry, o) => carry + o.margin, 0),
  };
}
