import React from 'react'

import {valueFormat} from "../../lib/formater";

import styles from './component.module.scss'

class ValueTable extends React.Component {

  maxHeight = '100%';

  constructor(props) {
    super(props);

    let values = props.values || [];

    if (props.sortable === true) {
      // sort desc by index 0
      values.sort((a, b) => {
        if (a[0].value > b[0].value) return 1;
        if (a[0].value < b[0].value) return -1;
        return 0;
      });
    }

    if (props.visibleCount) {
      this.maxHeight = /*header row*//*45 + */(props.visibleCount * /*body row*/48.5) + 'px';
    }

    this.state = {
      values: values,
      activeSortIndex: 0,
      activeSortDirection: 'desc',
    };

    this.onSortClick = this.onSortClick.bind(this);
  }

  onSortClick(index) {
    let sortDirection = this.state.activeSortDirection;

    if (this.state.activeSortIndex !== index) {
      sortDirection = 'desc';
    } else {
      if (sortDirection === 'desc') {
        sortDirection = 'asc';
      } else {
        sortDirection = 'desc';
      }
    }

    let values = this.state.values;
    values.sort((a, b) => {
      if (a[index].value > b[index].value) {
        if (sortDirection === 'desc') return 1;
        return -1;
      }

      if (a[index].value < b[index].value) {
        if (sortDirection === 'desc') return -1;
        return 1;
      }

      return 0;
    });

    this.setState({
      values: values,
      activeSortIndex: index,
      activeSortDirection: sortDirection,
    })
  }

  render() {
    return (
        <div className={`${styles.valueTable} ${this.props.sortable ? styles.sortable : ''} ${this.props.className || ''}`}>

          <div className={`row ${styles.header}`}>
            {(this.props.headers || []).map((header, i) => (
                <div key={i} className={`col-3`}>
                  {this.props.sortable &&
                    <button
                        className={`${styles.sort} ${this.state.activeSortIndex === i ? styles.active : ''} ${this.state.activeSortIndex === i && this.state.activeSortDirection === 'desc' ? styles.desc : ''}`}
                        onClick={() => {this.onSortClick(i)}}
                    >
                      {header}
                    </button>
                  }
                  {!this.props.sortable &&
                    {header}
                  }
                </div>
            ))}
          </div>

          <div className={styles.content} style={{
            maxHeight: this.maxHeight,
          }}>
            {this.state.values.map((row, i) => (
              <div key={i} className={`row`}>
                {row.map((col, k) => {
                  const f = valueFormat(col);
                  return (
                      <div key={k} className={`col-3 ${f.className}`}>{f.value}</div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
    );
  }
}

export default ValueTable
