import React from 'react'

import {IProps} from "./props";
import {valueFormat} from "../../lib/formater";

export default (props: IProps) => {
  const format = valueFormat({
    value: props.value,
    unit: props.unit,
  });

  return (
      <span className={`${format.className} ${props.className || ''}}`}>{format.value}</span>
  );
}
