import React from 'react'

import ValueList from '../../../../components/value-list'
import {IProps} from "./props";

export default (props: IProps) => {
  return (
    <div className={`alert alert-custom alert-default pb-0 ${props.className || ''}`}>
      <div className={'alert-text row'}>

        <ValueList className={'col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-6'} values={[
          {
            label: 'Gesamt Lagerbestellung',
            value: props.buyAmountTotal,
            unit: 'g'
          },
          {
            label: 'Restmenge Lagerbestellung',
            value: props.buyAmountRemainder,
            unit: 'g'
          },
          {
            label: 'Marge Rest',
            value: props.profitMargin,
            unit: 'currency'
          },
          {
            label: 'Gewinn gesamt',
            value: props.profitTotal,
            unit: 'currency'
          },
          {
            label: 'Gesamt Verkaufsaufträge',
            value: props.sellAmountTotal,
            unit: 'g'
          },
          {
            label: 'Verkauf an Provider',
            value: props.sellAmountRemainder,
            unit: 'g'
          },
        ]} />

        <ValueList className={'col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-6'} values={[
          {
            label: 'Reduzierung Verkaufsverträge',
            value: props.reductionBySellOrders,
            unit: 'g'
          },
          {
            label: 'Gewinn',
            value: props.profitBySellOrderReduction || 0,
            unit: 'currency'
          }
        ]} />

        <ValueList className={'col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-6'} values={[
          {
            label: 'Reduzierung Metalle.Direct Lager',
            value: props.reductionInMdInventory,
            unit: 'g'
          },
          {
            label: 'Gewinn',
            value: props.profitByMdInventoryReduction || 0,
            unit: 'currency'
          }
        ]} />

        <ValueList className={'col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-6'} values={[
          {
            label: 'Verkauf Metalle.Direct Lager',
            value: props.reductionByProviderSale,
            unit: 'g'
          },
          {
            label: 'Gewinn',
            value: props.profitByProviderSale || 0,
            unit: 'currency'
          }
        ]} />

      </div>
    </div>
  );
}
