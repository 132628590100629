import { IOrdersOrderEntryViewmodel, IOrdersOrderViewmodel } from "./viewmodel/order";
import { IResponseMetal } from "../../../../functions/src/backend/routes/orders/model/orders_view";
import { IProductExportData } from "./components/product/statemodel";

export type IOrdersTransactionProductStatemodel = IResponseMetal;

export type IOrdersTransactionMode = 'buy' | 'sell' | 'init';

export interface IOrdersTransactionStatemodel {
  product: IResponseMetal
  exportData: IProductExportData
  amountInKg: number
  mode: IOrdersTransactionMode
}

export interface IOrdersStatemodel {
  receivedBom: IOrdersOrderEntryViewmodel[]
  receivedSom: IOrdersOrderEntryViewmodel[]
  loaded: boolean
  isPlacingOrder: boolean
  transactions: IOrdersTransactionStatemodel[]
  profitTotal: number
}

export function TransactionModel(order: IOrdersOrderViewmodel, mode: IOrdersTransactionMode): IOrdersTransactionStatemodel {
  return {
    product: order.product,
    exportData: {
      product: order.product,
      reducingOrders_md1: [],
      reducingOrders_md2: [],
      reducingOrders_so1: [],
      reducingOrders_so2: []
    },
    amountInKg: order.amountInGramm / 1000,
    mode,
  };
}
