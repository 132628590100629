import {IBalanceItemViewmodel} from "../../product-balance/viewmodel/item";

export default function (
    id: any,
    date: Date,
    amountInGramm: number,
    inPricePerKg: number,
    outPricePerKg: number,
    altOutPricePerKg?: number
): IBalanceItemViewmodel {
  const inPrice = inPricePerKg * (amountInGramm / 1000);
  const outPrice = outPricePerKg * (amountInGramm / 1000);
  const altOutPrice: number | undefined = altOutPricePerKg ? altOutPricePerKg * (amountInGramm / 1000) : undefined;

  return {
    id: id.toString(),
    value: amountInGramm,
    unit: 'g',
    in: {
      pricePerKg: inPricePerKg,
      price: inPrice,
      date,
    },
    out: {
      pricePerKg: outPricePerKg,
      price: outPrice,
      profit: outPrice - inPrice,
    },
    altOut: altOutPricePerKg && altOutPrice ? {
      pricePerKg: altOutPricePerKg,
      price: altOutPrice,
      profit: altOutPrice - inPrice,
    } : undefined,
  };
}
