import React from 'react'

import ValueList from '../value-list'

export default (props) => (
    <div className={`alert alert-custom alert-default ${props.className || ''}`}>
      <div className={'alert-text row'}>
        <ValueList values={props.values} />
      </div>
    </div>
)
