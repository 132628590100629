import React from 'react';
import { Content, SubHeader } from '../../layout/default'
import Card from '../../components/card/card'
import neatCsv from 'neat-csv';
import ViewModel from './viewmodel/bankingEntries'
import FunctionsClient from '../../lib/functions-client'
import Dropzone from 'react-dropzone';
import ImportOverview from './components/import-overview/import-overview'
import LoadingButton from '../../components/loading-button/loading-button'
import styles from './component.module.scss'
import { sha256 } from 'js-sha256';

class BankingPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            inputText: "keine Datei Vorhanden.",
            loaded: false,
            isImporting: false,
            rejected: false,
            totalRows: 0,
            succesfulRows: 0,
            data: [],
            csvFileRef: null,
            error: false
        };
        this.onDrop = this.onDrop.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onDrop(theFiles: File[]) {
        this.setState({
            inputText: theFiles[0] !== undefined ? theFiles[0].name : "",
            csvFileRef: theFiles[0]
        });
    }

    onSubmit() {
        if (this.state.csvFileRef === null) {
            return;
        }
        if (this.state.isImporting) {
            return;
        }
        this.setState({ isImporting: true });
        const reader = new FileReader();
        reader.onerror = () => {
            this.setState({
                csvFileRef: null,
                error: true,
                isLoading: false,
                isImporting: false
            });
        }
        reader.onload = () => {
            const result = reader.result;
            neatCsv(result as string).then((value => {
                const models: ViewModel[] = [];
                for (let data of value) {
                    const entry = Object.values(data);
                    const checksum = sha256(entry[0]
                        + entry[1]
                        + entry[2]
                        + entry[3]
                        + entry[4]);

                    const model: ViewModel = {
                        Datum: entry[0],
                        Sender: entry[1],
                        Kontoverbindung: entry[2],
                        Betreff: entry[3],
                        Betrag: entry[4],
                        checksum: checksum
                    };
                    models.push(model);
                }
                FunctionsClient.validateBanking(models).then(data => {
                    if (data !== null) {

                        this.setState({
                            csvFileRef: null,
                            error: false,
                            isImporting: false,
                            totalRows: data.total,
                            succesfulRows: data.success,
                            loaded: true,
                            data: data.noRef
                        });
                    } else {
                        this.setState({
                            csvFileRef: null,
                            error: true,
                            isLoading: false,
                            isImporting: false
                        });
                    }
                }
                );
            }));

        }
        reader.readAsText(this.state.csvFileRef);
    }

    render() {
        return (
            <>
                <SubHeader title={'Buchungen'} subtitle={this.state.loaded ? null : 'Bitte CSV importieren um Buchungsabgleich zu starten.'}>
                    <div className={'h5 mb-0 font-weight-bold'}>
                        {this.state.loaded &&
                            <span className={'text-dark font-weight-bold'}>
                                {"Erfolgreich zugeordnet: " + this.state.succesfulRows.toString() + "/" + this.state.totalRows.toString()
                                }</span>}
                    </div>
                </SubHeader>
                <Content>
                    <div className={styles.uploadWrapper + " col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3"}>

                        <Dropzone
                            onDrop={acceptedFiles => { this.onDrop(acceptedFiles); }}
                            accept='text/csv'
                            onDropRejected={(fileReject, event) => { this.setState({ inputText: "Das ist keine csv Datei", rejected: true }); }}
                            maxFiles={1}>
                            {({ getRootProps, getInputProps }) => (
                                <div>
                                    <span className="light">{"Drag'n'Drop oder ins Feld Klicken um CSV einzufügen."}</span>
                                    <div {...getRootProps()} className={styles.upload}>
                                        <input {...getInputProps()} className={styles.upload} />
                                        <span className={'text-dark font-weight-bold'}>{this.state.inputText}
                                        </span>
                                    </div>
                                </div>

                            )}
                        </Dropzone>

                        <div>
                            <LoadingButton
                                onClick={() => { this.onSubmit(); }}
                                disabled={this.state.csvFileRef === null}
                                isLoading={this.state.isImporting}>
                                {"Importieren & Abgleichen"}
                            </LoadingButton>
                        </div>
                    </div>
                    <div className={styles.error}>
                        {this.state.error &&
                            <span className={'negative font-weight-bold '}>{"Beim Importieren ist ein Fehler aufgetreten."}
                            </span>}
                    </div>

                    <Card title="Übersicht">
                        <ImportOverview loaded={this.state.loaded} data={this.state.data} ></ImportOverview>
                    </Card>

                </Content>
            </>
        );
    }
}
export default BankingPage;