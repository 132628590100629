import moment from 'moment'
import {IProductPropsOrderEntry} from "../props";

export interface ITabelViewmodel {
  value: number | Date
  unit: string
}
export default function (data: IProductPropsOrderEntry): ITabelViewmodel[] {
  return [
    {
      value: data.amountInGramm,
      unit: 'g',
    },
    {
      value: data.total,
      unit: 'currency-raw',
    },
    {
      value: moment(data.date).toDate(),
      unit: 'date',
    },
    {
      value: data.margin,
      unit: 'currency',
    },
  ];
}
