import React from 'react'

import {IState} from "./state";
import {IProps} from "./props";

import styles from './component.module.scss'

class Card extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      collapsed: false
    };

    this.onToggleCollapseClick = this.onToggleCollapseClick.bind(this);
  }

  onToggleCollapseClick() {
    this.setState(oldState => ({
      collapsed: !oldState.collapsed,
    }));
  }

  render() {
    return (
        <div id={this.props.id || undefined} className={`card card-custom gutter-b ${styles.card}${this.props.collapsible ? ' ' + styles.collapsible : ''}${this.state.collapsed ? ' ' + styles.collapsed : ''}${this.props.className ? ' ' + this.props.className : ''}`}>
          {this.props.collapsible &&
            <button className={`card-header ${styles.header}`} onClick={this.onToggleCollapseClick}>
              <span className={'card-title'}>
                <h2 className={'card-label'}>{this.props.title}</h2>
              </span>
            </button>
          }

          {!this.props.collapsible &&
            <div className={`card-header ${styles.header}`}>
              <div className={'card-title'}>
                <h2 className={'card-label'}>{this.props.title}</h2>
              </div>
            </div>
          }
          <div className={`card-body ${styles.body} ${this.props.bodyClassName || ''}`}>
            {this.props.children}
          </div>
        </div>
    )
  }
}

export default Card
