import React from 'react'
import OrdersPage from '../orders/orders'
import BankingPage from '../banking/banking'
import { Layout } from '../../layout/default'
import { NavTarget } from '../../lib/static/enums'
import UnblockPage from '../unblock/unblock'
class NavigationPage extends React.Component<any, any> {

    menuClicked = (ev: { currentTarget: { value: any; }; }) => {

        this.setState({
            Requested: ev.currentTarget.value
        });
    }
    constructor(props: any) {
        super(props);
        this.state = {
            Requested: NavTarget.ORDERS,
        };
    }

    render() {

        return (
            <Layout menuclicked={this.menuClicked}>
                {this.state.Requested === NavTarget.ORDERS && <OrdersPage />}
                {this.state.Requested === NavTarget.BANKING && <BankingPage />}
                {this.state.Requested === NavTarget.UNBLOCK && <UnblockPage />}
            </Layout>
        );
    }
}
export default NavigationPage;