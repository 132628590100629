import React from 'react'

import {valueFormat} from "../../lib/formater";

import styles from './component.module.scss'

export default (props) => {

  const values = (props.values || []).map(data => {
    const f = valueFormat(data);
    return {
      label: data.label,
      value: f.value,
      valueClassName: f.className,
    };
  });

  return (
      <div className={`${styles.valueList} ${props.className || ''}`}>
        {values.map((data, i) => (
            <div className={`row ${i + 1 < values.length ? 'mb-4' : ''}`} key={i}>
              <div className={`col-8`}>{data.label}</div>
              <div className={`col-4 ${styles.val} ${data.valueClassName}`}>{data.value}</div>
            </div>
        ))}
      </div>
  );
}
