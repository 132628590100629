import React from 'react'

import Skeleton from 'react-loading-skeleton'

import {numberFormat, dateFormat, currencyFormat, valueFormat} from "../../../../lib/formater";

import {IOrdersSummaryProductProp, Props} from "./props";

import Card from '../../../../components/card/card'

import styles from './component.module.scss'

export default (props: Props) => {

  const amountFormater = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 3, maximumFractionDigits: 3});

  const sum = props.orders.reduce((carry: any, p: IOrdersSummaryProductProp) => {
    carry.margin += p.margin;
    carry.total = p.orders.reduce((c, o) => c + o.total, 0);
    return carry;
  }, {
    margin: 0,
    total: 0
  });

  const sumMarginData = valueFormat({
    value: sum.margin,
    unit: 'currency'
  });
  
  return (
      <Card className={styles.ordersSummary} title={'Gesamt ' + props.title}>
        <p>
          Folgende Auflistung zeigt die Summe der {props.title} gruppiert nach Metall
          {props.date &&
            <>
              <br />seit dem {dateFormat(props.date)}
            </>
          }
        </p>

        <table className={`table mb-0 ${styles.table}`}>
          <thead>
            <tr>
              <th colSpan={2}>Metall</th>
              <th>Menge</th>
              <th>&empty; Preis/Kg</th>
              <th>Marge</th>
              <th>Gesamt</th>
            </tr>
          </thead>
          <tbody>
            {!props.loaded &&
              <tr>
                <td><Skeleton width={45} height={45} /></td>
                <td><Skeleton width={75} /></td>
                <td><Skeleton width={50} /></td>
                <td><Skeleton width={75} /></td>
                <td><Skeleton width={50} /></td>
                <td><Skeleton width={75} /></td>
              </tr>
            }

            {props.orders.map((p: IOrdersSummaryProductProp, i: number) => {
              const marginData = valueFormat({
                value: p.margin,
                unit: 'currency'
              });
              
              return (
                  <tr key={i}>
                    <td>
                      <div className={`symbol symbol-50 symbol-light mr-4 ${styles.productImg}`}>
                        <span className="symbol-label">
                          {p.product.imgUrl &&
                            <img src={p.product.imgUrl} className="h-75 align-self-end" alt={p.product.name} />
                          }
                          {!p.product.imgUrl &&
                            <>{p.product.name[0].toUpperCase()}</>
                          }
                        </span>
                      </div>
                    </td>
                    <td>
                      {p.product.name}
                      <span>
                        {p.orders.length} {props.title}
                      </span>
                    </td>
                    <td>
                      {amountFormater.format(p.amountInGramm / 1000)} Kg
                      <span>&empty; {amountFormater.format((p.amountInGramm / 1000) / p.orders.length)} Kg</span>
                    </td>
                    <td>
                      {currencyFormat(p.averagePricePerKg)}
                    </td>
                    <td className={marginData.className}>
                      {marginData.value}
                    </td>
                    <td>{currencyFormat(p.orders.reduce((carry, o) => carry + o.total, 0))}</td>
                  </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td colSpan={3}>Summe</td>
              <td className={sumMarginData.className}>
                {!props.loaded &&
                  <Skeleton width={50} />
                }
                {props.loaded &&
                  <>{sumMarginData.value}</>
                }
              </td>
              <td>
                {!props.loaded &&
                  <Skeleton width={75} />
                }
                {props.loaded &&
                  <>{currencyFormat(sum.total)}</>
                }
              </td>
            </tr>
          </tfoot>
        </table>
      </Card>
  )
}
