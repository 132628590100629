import React from 'react'
import ViewModel from '../../viewmodel/bankingEntries'
import Skeleton from 'react-loading-skeleton'
import { dateFormat, currencyFormat } from '../../../../lib/formater'
//@ts-ignore
import styles from './component.module.scss'

const numberFormater = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

export default (props: any) => {
  const bookings = props.data as ViewModel[];
  return (
    <div className={styles.importOverview}>

      <table className={`table ${styles.table}`}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Datum</th>
            <th scope="col">Von</th>
            <th scope="col">Bankdaten</th>
            <th scope="col">Betreff</th>
            <th scope="col">Betrag</th>

          </tr>
        </thead>
        <tbody>
          {!props.loaded &&
            <tr>
              <td><Skeleton /></td>
              <td><Skeleton /></td>
              <td><Skeleton /></td>
              <td><Skeleton /></td>
              <td><Skeleton /></td>
              <td><Skeleton /></td>
            </tr>
          }

          {bookings.map((t, i) => {
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{t.Datum}</td>
                <td>{t.Sender}</td>
                <td>{t.Kontoverbindung}</td>
                <td>{t.Betreff}</td>
                { parseFloat(t.Betrag) > 0 &&
                  <td className="positive">{currencyFormat(parseFloat(t.Betrag))}</td>
                }
                { parseFloat(t.Betrag) < 0 &&
                  <td className="negative">{currencyFormat(parseFloat(t.Betrag))}</td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
