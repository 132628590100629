import React from 'react'

import Skeleton from 'react-loading-skeleton'

import {IProps} from "./props";
import {dateFormat, currencyFormat} from '../../../../lib/formater'

import styles from './component.module.scss'

const numberFormater = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 3, maximumFractionDigits: 3});

export default (props: IProps) => {
  const transactions = props.transactions.filter(t => t.amountInKg > 0);

  return (
      <div className={styles.transactionPreview}>
        <h5 className={'text-dark font-weight-bold'}>
          {props.mode === 'buy' &&
            <>Kaufen</>
          }
          {props.mode === 'sell' &&
            <>Verkaufen</>
          }
        </h5>

        <table className={`table ${styles.table}`}>
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Produkt</th>
            <th scope="col">Menge</th>
            <th scope="col">Preis / Kg</th>
            <th scope="col">Dollarkurs</th>
            <th scope="col">Datum</th>
          </tr>
          </thead>
          <tbody>
            {!props.loaded &&
                <tr>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                </tr>
            }

            {transactions.map((t, i) => {
              const priceModel = props.prices.find(p => p.metalId === t.product.id) || {
                metalId: t.product.id,
                sellToPerKg: 0,
                buyFromPerKg: 0,
              };

              let pricePerKg: number;

              switch (props.mode) {
                case 'buy':
                  pricePerKg = priceModel.buyFromPerKg;
                  break;
                case 'sell':
                  pricePerKg = priceModel.sellToPerKg;
                  break;
              }

              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{t.product.name}</td>
                  <td>{numberFormater.format(t.amountInKg)} Kg</td>
                  <td>{currencyFormat(pricePerKg)}</td>
                  <td>{currencyFormat(props.exchangeRate, '$')}</td>
                  <td>{dateFormat(props.date)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
  );
}
