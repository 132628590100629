class Storage {
  _setObject(key: string, obj: any) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  _getObject(key: string): any {
    // @ts-ignore
    return JSON.parse(localStorage.getItem(key));
  }

  getProfile(): any {
    return this._getObject('profile');
  }

  setProfile(profile: any) {
    this._setObject('profile', profile);
  }

  getToken(): string | null  {
    return localStorage.getItem('token');
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  clear() {
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
  }
}

export default new Storage();
