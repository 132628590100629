import React from 'react'

import {ContainerBalanceCallback, IItem, IProps} from "./props";

import ValueBox from "../../../../components/value-box";
import Balance from "../product-balance/balance";
import {BalanceType} from "../product-balance/props";
import {IState} from "./state";

class ProductBalanceContainer extends React.Component<IProps, IState> {

  onBalanceChange: ContainerBalanceCallback;

  balance1: React.RefObject<any>;
  balance2: React.RefObject<any>;

  items1: IItem[] = [];
  items2: IItem[] = [];

  constructor(props: IProps) {
    super(props);

    this.items1 = this.initItems(props.type1, props.items);
    this.items2 = this.initItems(props.type2, props.items);

    this.balance1 = React.createRef();
    this.balance2 = React.createRef();

    this.onBalanceChange = props.onBalanceChange || ((type: BalanceType, items: string[]) => items);

    this.onChange = this.onChange.bind(this);

    this.state = {
      md1: {
        reduction: 0,
        yield: 0,
      },
      md2: {
        reduction: 0,
        yield: 0,
      },
      so1: {
        reduction: 0,
        yield: 0,
      },
      so2: {
        reduction: 0,
        yield: 0,
      },
    };
  }

  initItems(type: BalanceType, source: IItem[]): IItem[] {
    switch (type) {
      case "so2":
        return JSON.parse(JSON.stringify(source)).map((item: IItem) => {
          delete item.out;
          return item;
        });

      case "md2":
        return JSON.parse(JSON.stringify(source)).map((item: IItem) => {
          item.out = item.altOut;
          delete item.altOut;
          return item;
        });
    }

    return source;
  }

  onChange(type: BalanceType, selectedIds: string[]): string[] {
    let selectedItems: IItem[];

    switch (type) {
      case "so1":
      case "md1":
        selectedItems = this.items1.filter(item => selectedIds.includes(item.id));
        break;
      default:
        selectedItems = this.items2.filter(item => selectedIds.includes(item.id));
    }

    const newState = this.state;
    newState[type].reduction = selectedItems.reduce((carry, item) => carry + item.value, 0);
    newState[type].yield = selectedItems.reduce((carry, item) => {
      if (!item.out) {
        return carry - item.in.price;
      }
      return carry + item.out?.profit;
    }, 0);

    const acknowledgedIds = this.onBalanceChange(type, selectedIds, newState[type].yield);
    if (
        selectedIds.length !== acknowledgedIds.length ||
        selectedIds.filter(id => !acknowledgedIds.includes(id)).length !== 0
    ) {
      return acknowledgedIds;
    }

    this.setState(newState);

    let otherRef: React.RefObject<any>;

    switch (type) {
      case "md1":
        otherRef = this.balance2;
        break;
      case "md2":
        otherRef = this.balance1;
        break;
      case "so1":
        otherRef = this.balance2;
        break;
      case "so2":
        otherRef = this.balance1;
        break;
    }

    (otherRef!.current as Balance).updateUnavailableIds(acknowledgedIds);

    return acknowledgedIds;
  }

  balanceLabelLeft(type: BalanceType) {
    switch (type) {
      case "md1":
        return 'Reduzierung Lagerbestellung';
      case "md2":
        return 'Verkauf Provider';
      case "so1":
        return 'Reduzierung Lagerbestellung';
      case "so2":
        return 'Ankauf MetalleDirect';
    }
  }

  balanceLabelRight(type: BalanceType) {
    switch (type) {
      case "md1":
        return 'Metalle.Direct Lager';
      case "md2":
        return 'Metalle.Direct Lager';
      case "so1":
        return 'Aktuelle Verkaufsaufträge';
      case "so2":
        return 'Aktuelle Verkaufsaufträge';
    }
  }

  values(type: BalanceType) {
    switch (type) {
      case "md1":
        return [
          {
            label: 'Reduzierung Lagerbestellung',
            value: this.state.md1.reduction,
            unit: 'g'
          },
          {
            label: 'Gewinn',
            value: this.state.md1.yield,
            unit: 'currency'
          },
        ];
      case "md2":
        return [
          {
            label: 'Reduzierung Metalle.Direct Lager',
            value: this.state.md2.reduction,
            unit: 'g'
          },
          {
            label: '',
            value: this.state.md2.yield,
            unit: 'currency'
          },
        ];
      case "so1":
        return [
          {
            label: 'Reduzierung Lagerbestellung',
            value: this.state.so1.reduction,
            unit: 'g'
          },
          {
            label: 'Gewinn',
            value: this.state.so1.yield,
            unit: 'currency'
          },
        ];
      case "so2":
        return [
          {
            label: 'Reduzierung Verkaufsauftrag',
            value: this.state.so2.reduction,
            unit: 'g'
          },
          {
            label: 'Kosten',
            value: this.state.so2.yield,
            unit: 'currency'
          },
        ];
    }
  }

  render() {
    return (
        <>
          <ValueBox values={this.values(this.props.type1)} />

          <Balance
              className={'pb-4'}
              ref={this.balance1}
              type={this.props.type1}
              labelLeft={this.balanceLabelLeft(this.props.type1)}
              labelRight={this.balanceLabelRight(this.props.type1)}
              items={this.items1}
              onChange={this.onChange}
          />

          <ValueBox values={this.values(this.props.type2)} />

          <Balance
              className={'pb-4'}
              ref={this.balance2}
              type={this.props.type2}
              labelLeft={this.balanceLabelLeft(this.props.type2)}
              labelRight={this.balanceLabelRight(this.props.type2)}
              items={this.items2}
              onChange={this.onChange}
          />
        </>
    );
  }
}

export default ProductBalanceContainer
