import React from 'react'
import moment from 'moment'

import Skeleton from 'react-loading-skeleton'

import FunctionsClient from '../../lib/functions-client'

import { dateFormat, timeFormat } from '../../lib/formater'

import { Content, SubHeader } from '../../layout/default'

import Card from '../../components/card/card'
import ValueFormat from '../../components/value-format/value-format'
import LoadingButton from '../../components/loading-button/loading-button'

import TransactionPreview from './components/transaction-preview/transaction-preview'
import OrdersSummary from './components/orders-summary/orders-summary'
import Product from './components/product/product'

import { IOrdersStatemodel, IOrdersTransactionMode, IOrdersTransactionStatemodel, TransactionModel } from "./statemodel";

import {
  IOrderProviderPriceViewmodel,
  IOrdersOrderViewmodel,
  IOrdersProductViewmodel,
  IOrdersInventoryItemViewmodel, OrderViewModel,
} from './viewmodel/order'

import styles from './component.module.scss'

import { IResponseData } from "../../../../functions/src/backend/routes/orders/model/orders_view";
import { IProductExportData } from './components/product/statemodel'
import { IProviderBuy, IProviderSell, ITransferData, ITransferProdukt } from '../../../../functions/src/backend/routes/providerOrders/model/providerOrders_data'

class OrdersPage extends React.Component<any, IOrdersStatemodel> {

  date?: Date;

  usdRate: number = 0;
  buyOrders: IOrdersOrderViewmodel[] = [];
  sellOrders: IOrdersOrderViewmodel[] = [];
  inventory: IOrdersInventoryItemViewmodel[] = [];
  products: IOrdersProductViewmodel[] = [];
  providerPrices: IOrderProviderPriceViewmodel[] = [];
  profits = {};

  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
      isPlacingOrder: false,
      transactions: [],
      receivedSom: [],
      receivedBom: [],
      profitTotal: 0,
    };

    this.onProductChange = this.onProductChange.bind(this);
    this.onPlaceOrderClick = this.onPlaceOrderClick.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.setState({
      isPlacingOrder: false,
      loaded: false,
    }, () => {
      this.date = undefined;
      this.usdRate = 0;
      this.buyOrders = [];
      this.sellOrders = [];
      this.inventory = [];
      this.products = [];
      this.providerPrices = [];
      this.profits = {};
    });

    FunctionsClient.orders().then((data: IResponseData) => {
      debugger
      this.usdRate = data.usdRate;
      this.date = moment(data.since).toDate();

      this.buyOrders = data.buyOrders.map(o => OrderViewModel(o, data.metals));
      this.sellOrders = data.sellOrders.map(o => OrderViewModel(o, data.metals));
      // what is this?
      const recBuy = this.buyOrders;
      for (let rD of this.state.receivedBom) {
        for (let rr of recBuy) {
          var index = rr.orders.findIndex(x => x.id === rD.id);
          rr.orders.splice(index, 1);
        }
      }
      this.buyOrders = recBuy;
      const newBom = []
      for (let rr of recBuy) {
        for (let ord of rr.orders) {
          newBom.push(ord);
        }
      }
      const recSell = this.sellOrders;
      for (let rD of this.state.receivedSom) {
        for (let rr of recSell) {
          var iindex = rr.orders.findIndex(x => x.id === rD.id);
          rr.orders.splice(iindex, 1);
        }
      }
      this.sellOrders = recSell;
      const newSom = []
      for (let rr of recSell) {
        for (let ord of rr.orders) {
          newSom.push(ord);
        }
      }
      // end of: what is this?

      this.products = data.metals;
      this.inventory = data.inventory;
      this.providerPrices = data.providerPrices;
      const buyTransactions: IOrdersTransactionStatemodel[] = this.buyOrders.map(o => TransactionModel(o, 'buy'));
      const sellTransactions: IOrdersTransactionStatemodel[] = this.sellOrders.map(o => TransactionModel(o, 'sell'));

      this.setState({
        loaded: true,
        receivedBom: newBom,
        receivedSom: newSom,
        transactions: buyTransactions.concat(sellTransactions),
      });
    });
  }

  onProductChange(exportData: IProductExportData, mode?: IOrdersTransactionMode, amountInKg?: number, profit?: number) {
    if (profit) {
      // @ts-ignore
      this.profits[exportData.product.id] = profit;
    }
    let transactions = this.state.transactions;

    if (mode && typeof amountInKg !== "undefined") {
      const hasTransaction = this.state.transactions.find(t => mode === t.mode && t.exportData.product.id === exportData.product.id) !== undefined;

      if (!hasTransaction) {
        const product = exportData.product;
        transactions.push({
          product,
          mode,
          exportData,
          amountInKg,
        });
      } else {
        transactions = this.state.transactions.map(t => {
          if (mode !== t.mode || t.exportData.product.id !== exportData.product.id) {
            return t;
          }
          t.exportData = exportData;
          t.amountInKg = amountInKg;
          return t;
        });
      }
    }

    this.setState({
      transactions,
      profitTotal: Object.keys(this.profits).reduce((carry, key) => {
        // @ts-ignore
        return carry + this.profits[key];
      }, 0)
    });
  }

  onPlaceOrderClick() {
    if (this.state.isPlacingOrder) {
      return;
    }

    this.setState({
      isPlacingOrder: true,
    });


    let transferData: ITransferData = {
      dollarRate: this.usdRate,
      dollarDate: moment().toISOString(),
      products: [],
      buyOrderIds: [],
      sellOrderIds: [],
      mdLagerIds: []
    }
    for (let bom of this.buyOrders) {
      for (let ord of bom.orders) {
        transferData.buyOrderIds.push(ord.id);
      }
    }
    for (let som of this.sellOrders) {
      for (let ord of som.orders) {
        transferData.sellOrderIds.push(ord.id);
      }
    }
    for (let mtl of this.products) {
      const product: ITransferProdukt = {
        priceId: this.providerPrices.find(pp => pp.metalId === mtl.id)!.priceId,
        metalId: mtl.id,
        buyOrderIds: [], sellOrderIds: [], providerSell: undefined, providerBuy: undefined
      };
      let boms = this.buyOrders.filter(o => o.product.id === mtl.id)
      for (let bom of boms) {
        for (let ord of bom.orders) {
          product.buyOrderIds.push(ord.id);
        }
      }
      let soms = this.sellOrders.filter(o => o.product.id === mtl.id)
      for (let som of soms) {
        for (let ord of som.orders) {
          product.sellOrderIds.push(ord.id);
        }
      }
      let trans = this.state.transactions.filter((o => o.product.id === mtl.id))
      for (let tt of trans) {
        switch (tt.mode) {
          case "buy":
            const buy: IProviderBuy = {
              amountinKg: tt.amountInKg,
              price: this.providerPrices.filter(o => o.metalId === mtl.id)[0].buyFromPerKg * tt.amountInKg,
              saleIdstoReduce: tt.exportData.reducingOrders_so1.map((val) => { return val.id; }),
              mdLagerIdstoReduce: tt.exportData.reducingOrders_md1.map((val) => { return val.id; })
            }
            product.providerBuy = buy;
            for (let str of buy.mdLagerIdstoReduce) {
              transferData.mdLagerIds.push(str);
            }
            break;
          case "sell":
            const sell: IProviderSell = {
              amountinKg: tt.amountInKg,
              price: this.providerPrices.filter(o => o.metalId === mtl.id)[0].buyFromPerKg * tt.amountInKg,
              saleIdstoKeep: tt.exportData.reducingOrders_so2.map((val) => { return val.id; }),
              mDLagerIdstoSell: tt.exportData.reducingOrders_md2.map((val) => { return val.id; })

            }
            product.providerSell = sell;
            for (let str of sell.mDLagerIdstoSell) {
              transferData.mdLagerIds.push(str);
            }
            break;
        }
      }
      transferData.products.push(product);
    }



    FunctionsClient.placeProviderOrder(transferData).then(() => {
      this.load();
      this.setState({
        isPlacingOrder: false,
      });
    }
    )


  }

  render() {
    return (
      <>
        <SubHeader title={'Aufträge'} subtitle={this.state.loaded ? 'seit dem ' + dateFormat(this.date) + ' um ' + timeFormat(this.date) + ' Uhr' : null}>
          <div className={'h5 mb-0 font-weight-bold'}>
            <span className={'text-dark font-weight-bold'}>Gewinn bei dieser Bestellung: </span>
            {this.state.loaded &&
              <ValueFormat value={this.state.profitTotal} unit={'currency'} />
            }
            {!this.state.loaded &&
              <Skeleton width={75} height={19} />
            }
          </div>
        </SubHeader>

        <Content>
          <div className={'row'}>
            <div className={'col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12'}>
              <OrdersSummary
                loaded={this.state.loaded}
                title={'Kaufaufträge'}
                date={this.date}
                orders={this.buyOrders}
              />
            </div>

            <div className={'col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12'}>
              <OrdersSummary
                loaded={this.state.loaded}
                title={'Verkaufsaufträge'}
                date={this.date}
                orders={this.sellOrders}
              />
            </div>
          </div>

          {(this.products || []).map((p, i) => (
            <Product
              key={i}
              id={'product-' + p.id}
              usdRate={this.usdRate}
              product={p}
              providerPricePerKg={this.providerPrices.find(pp => pp.metalId === p.id)!.buyFromPerKg}
              buyOrders={this.buyOrders.filter(o => o.product.id === p.id)}
              sellOrders={this.sellOrders.filter(o => o.product.id === p.id)}
              inventory={this.inventory.filter(ie => ie.metalId === p.id)}
              collapsible={true}
              onChange={this.onProductChange}
            />
          ))}

          <Card title={'Lagerbestellung'}>
            <>
              <div className={'row'}>
                <div className={'col-xl-6 col-lg-12 col-md-12 col-sm-12'}>
                  <TransactionPreview
                    loaded={this.state.loaded}
                    mode={'buy'}
                    date={moment().toDate()}
                    exchangeRate={this.usdRate}
                    prices={this.providerPrices}
                    transactions={this.state.transactions.filter(t => t.mode === 'buy')}
                  />
                </div>

                <div className={'col-xl-6 col-lg-12 col-md-12 col-sm-12'}>
                  <TransactionPreview
                    loaded={this.state.loaded}
                    mode={'sell'}
                    date={moment().toDate()}
                    exchangeRate={this.usdRate}
                    prices={this.providerPrices}
                    transactions={this.state.transactions.filter(t => t.mode === 'sell')}
                  />
                </div>
              </div>

              {this.state.loaded &&
                <LoadingButton
                  className={styles.btnSend}
                  disabled={this.state.transactions.length === 0}
                  isLoading={this.state.isPlacingOrder}
                  onClick={this.onPlaceOrderClick}
                >Lagerbestellung absenden</LoadingButton>
              }
            </>
          </Card>
        </Content>
      </>
    );
  }

}

export default OrdersPage
