import React from 'react'

import Storage from './lib/storage'

import LoginPage from './pages/login/login'
import NavigationPage from "./pages/navigation/navigation";

import './styles/theme.scss'

class Root extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const authToken = Storage.getToken();

    this.state = {
      isAuthenticated: authToken != null,
    };

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
  }

  onLoginSuccess() {
    this.setState({
      isAuthenticated: true,
    });
  }


  render() {
    return (
      <>
        {!this.state.isAuthenticated &&
          <LoginPage onLoginSuccess={this.onLoginSuccess} />
        }

        {this.state.isAuthenticated &&
          <NavigationPage />
        }
      </>
    );
  }
}

export default Root;
