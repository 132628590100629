import React from 'react'
import moment from 'moment'

import { IProductProps, IProductPropsOrder, IProductPropsOrderEntry, OnChangeCallback } from "./props";
import { IProductExportData, IProductOrderReductionItem, IProductStatemodel } from "./statemodel";

import TableViewmodel, { ITabelViewmodel } from './viewmodel/table'
import BalanceViewmodel from './viewmodel/balance'

import Card from '../../../../components/card/card'
import ValueList from '../../../../components/value-list'
import ValueTable from '../../../../components/value-table'

import Overview from '../product-overview/product-overview'
import BalanceContainer from '../product-balance-container/container'

import { BalanceType } from "../product-balance/props";

import styles from './component.module.scss'
import { IBalanceItemViewmodel } from "../product-balance/viewmodel/item";

class Product extends React.Component<IProductProps, IProductStatemodel> {

  tableData: ITabelViewmodel[][] = [];

  buyOrderMarginTotal: number = 0;

  buyOrderEntries: IProductPropsOrderEntry[];
  sellOrderEntries: IProductPropsOrderEntry[];

  balanceMdInventory: IBalanceItemViewmodel[] = [];
  balanceSellOrders: IBalanceItemViewmodel[] = [];

  buyOrdersAmountTotal = 0;
  sellOrdersAmountTotal = 0;

  onChange: OnChangeCallback;

  constructor(props: IProductProps) {
    super(props);

    this.onChange = this.props.onChange || (() => { return; });

    this.buyOrderEntries = props.buyOrders.flatMap((o: IProductPropsOrder) => o.orders);
    this.sellOrderEntries = props.sellOrders.flatMap((o: IProductPropsOrder) => o.orders);

    this.buyOrdersAmountTotal = this.buyOrderEntries.reduce((carry, o) => carry + o.amountInGramm, 0);
    this.sellOrdersAmountTotal = this.sellOrderEntries.reduce((carry, o) => carry + o.amountInGramm, 0);

    this.buyOrderMarginTotal = props.buyOrders.reduce((carry, o) => carry + o.margin, 0);

    this.tableData = this.buyOrderEntries.map((e: IProductPropsOrderEntry) => TableViewmodel(e));

    this.balanceSellOrders = this.sellOrderEntries.map((o, i) => BalanceViewmodel(
      o.id,
      o.date,
      o.amountInGramm,
      o.pricePerKg,
      o.sellToCustomerPricePerKg
    ));

    this.balanceMdInventory = props.inventory.map((iItem, i) => BalanceViewmodel(
      iItem.id,
      moment(iItem.dateAdded).toDate(),
      iItem.amount,
      iItem.originalPricePerKg,
      iItem.sellToProviderPricePerKg,
      iItem.sellToCustomerPricePerKg,
    ));
    const exportData: IProductExportData = {
      product: this.props.product,
      reducingOrders_md1: [],
      reducingOrders_md2: [],
      reducingOrders_so1: [],
      reducingOrders_so2: []
    }
    this.state = {
      exportData: exportData,
      reducingOrders_so1: [],
      reducingOrders_so2: [],
      reducingOrders_md1: [],
      reducingOrders_md2: [],
      buyOrdersAmountRemainder: this.buyOrdersAmountTotal,
      sellOrdersAmountRemainder: this.sellOrdersAmountTotal,
      profitTotal_so1: 0,
      profitTotal_so2: 0,
      profitTotal_md1: 0,
      profitTotal_md2: 0,
      profitMargin: this.calculateProfitMargin(this.buyOrdersAmountTotal),
    };

    this.onBalanceChange = this.onBalanceChange.bind(this);
  }

  componentDidMount() {
    //TODO: Changed that to get values
    this.onChange(this.state.exportData, 'init', 0, this.state.profitMargin);
  }

  onBalanceChange(type: BalanceType, selectedIds: string[], profit: number): string[] {
    const selectedItems = this.balanceSellOrders.concat(this.balanceMdInventory)
      .filter(o => selectedIds.includes(o.id)) as IProductOrderReductionItem[];

    const newState = {
      exportData: {
        product: this.props.product,
        buyOrders: this.props.buyOrders,
        sellOrders: this.props.sellOrders,
        reducingOrders_so1: type === 'so1' ? selectedItems : this.state.reducingOrders_so1,
        reducingOrders_so2: type === 'so2' ? selectedItems : this.state.reducingOrders_so2,
        reducingOrders_md1: type === 'md1' ? selectedItems : this.state.reducingOrders_md1,
        reducingOrders_md2: type === 'md2' ? selectedItems : this.state.reducingOrders_md2
      },
      reducingOrders_so1: type === 'so1' ? selectedItems : this.state.reducingOrders_so1,
      reducingOrders_so2: type === 'so2' ? selectedItems : this.state.reducingOrders_so2,
      reducingOrders_md1: type === 'md1' ? selectedItems : this.state.reducingOrders_md1,
      reducingOrders_md2: type === 'md2' ? selectedItems : this.state.reducingOrders_md2,
      buyOrdersAmountRemainder: this.state.buyOrdersAmountRemainder,
      sellOrdersAmountRemainder: this.state.sellOrdersAmountRemainder,
      profitTotal_so1: this.state.profitTotal_so1,
      profitTotal_so2: this.state.profitTotal_so2,
      profitTotal_md1: this.state.profitTotal_md1,
      profitTotal_md2: this.state.profitTotal_md2,
      profitMargin: this.state.profitMargin,
    };

    newState.buyOrdersAmountRemainder = this.buyOrdersAmountTotal - newState.reducingOrders_so1.concat(newState.reducingOrders_md1)
      .reduce((carry, o) => carry + o.value, 0);

    newState.sellOrdersAmountRemainder = this.sellOrdersAmountTotal
      - newState.reducingOrders_so1.concat(newState.reducingOrders_so2).reduce((carry, o) => carry + o.value, 0)
      + newState.reducingOrders_md2.reduce((carry, o) => carry + o.value, 0);

    // invalid value -> return old state
    if (newState.buyOrdersAmountRemainder < 0 || newState.sellOrdersAmountRemainder < 0) {
      // @ts-ignore
      return this.state['reducingOrders_' + type].map(i => i.id);
    }

    // trigger "onChange" with updated values

    // @ts-ignore
    newState['profitTotal_' + type] = profit;
    newState.profitMargin = this.calculateProfitMargin(newState.buyOrdersAmountRemainder);

    const profitTotal = newState.profitMargin + newState.profitTotal_so1 + newState.profitTotal_md1 + newState.profitTotal_md2;

    this.setState(newState, () => {
      switch (type) {
        case "so1":
          this.onChange(this.state.exportData, 'buy', newState.buyOrdersAmountRemainder / 1000, profitTotal);
          this.onChange(this.state.exportData, 'sell', newState.sellOrdersAmountRemainder / 1000);
          break;
        case "md1":
          this.onChange(this.state.exportData, 'buy', newState.buyOrdersAmountRemainder / 1000, profitTotal);
          break;
        case "so2":
        case "md2":
          this.onChange(this.state.exportData, 'sell', newState.sellOrdersAmountRemainder / 1000, profitTotal);
          break;
      }
    });

    return selectedIds;
  }

  calculateProfitMargin(buyOrdersAmountRemainder: number): number {
    return buyOrdersAmountRemainder > 0 && this.buyOrdersAmountTotal > 0 ?
      ((buyOrdersAmountRemainder / (this.buyOrdersAmountTotal / 100)) / 100) * this.buyOrderMarginTotal : 0;
  }

  render() {
    return (
      <Card
        className={`text-dark ${styles.product} ${styles.box}`}
        bodyClassName={'pb-xxl-0 pb-xl-6 pb-lg-6 pb-md-6 pb-sm-6'}
        title={this.props.product.name}
        collapsible={this.props.collapsible}
      >
        {/* overview */}
        <Overview
          className={styles.boxContent}
          buyAmountTotal={this.buyOrdersAmountTotal}
          buyAmountRemainder={this.state.buyOrdersAmountRemainder}
          buyMarginTotal={this.buyOrderMarginTotal}
          sellAmountTotal={this.sellOrdersAmountTotal}
          sellAmountRemainder={this.state.sellOrdersAmountRemainder}
          profitMargin={this.state.profitMargin}
          profitTotal={this.state.profitMargin + this.state.profitTotal_so1 + this.state.profitTotal_md1 + this.state.profitTotal_md2}
          profitBySellOrderReduction={this.state.profitTotal_so1}
          profitByMdInventoryReduction={this.state.profitTotal_md1}
          profitByProviderSale={this.state.profitTotal_md2}
          reductionBySellOrders={
            this.state.reducingOrders_so1.reduce((carry, o) => carry + o.value, 0) +
            this.state.reducingOrders_so2.reduce((carry, o) => carry + o.value, 0)
          }
          reductionInMdInventory={
            this.state.reducingOrders_md1.reduce((carry, o) => carry + o.value, 0)
          }
          reductionByProviderSale={
            this.state.reducingOrders_md2.reduce((carry, o) => carry + o.value, 0)
          }
        />

        <div className={'row'}>

          {/* aside */}
          <div className={`col-xxl-4 col-xl-5 col-lg-12 col-md-12 col-sm-12 mb-0 ${styles.box} ${styles.em}`}>

            <div className={`alert alert-custom alert-default ${styles.boxContent}`}>
              <div className={'alert-text row'}>
                <ValueList values={[
                  {
                    label: 'Lagerbestellung beim Provider',
                    value: this.state.buyOrdersAmountRemainder,
                    unit: 'g'
                  },
                  {
                    label: 'Datum',
                    value: moment().toDate(),
                    unit: 'date'
                  },
                  {
                    label: 'aktueller Kilopreis',
                    value: this.props.providerPricePerKg,
                    unit: 'currency-raw'
                  },
                  {
                    label: 'Dollarkurs',
                    value: this.props.usdRate,
                    unit: '$'
                  },
                  {
                    label: 'Kosten',
                    value: (this.state.buyOrdersAmountRemainder / 1000) * this.props.providerPricePerKg,
                    unit: 'currency-raw'
                  }
                ]} />
              </div>
            </div>

            <ValueTable
              className={`mb-6 ${styles.transfer}`}
              headers={[
                'Menge',
                'Preis',
                'Datum',
                'Marge',
              ]}
              values={this.tableData}
              sortable={true}
              visibleCount={10}
            />
          </div>

          {/* main */}
          <div className={`col-xxl-8 col-xl-7 col-lg-12 col-md-12 col-sm-12 row pr-0`}>

            <div className={`col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-xl-0 pr-lg-0 pr-md-0 pr-sm-0 ${this.balanceSellOrders.length === 0 ? styles.noBalance : ''}`}>
              {this.balanceSellOrders.length > 0 &&
                <BalanceContainer
                  items={this.balanceSellOrders}
                  onBalanceChange={this.onBalanceChange}
                  type1={'so1'}
                  type2={'so2'}
                />
              }
              {this.balanceSellOrders.length === 0 &&
                <p>keine Verkaufsaufträge</p>
              }
            </div>

            <div className={`col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-0 ${this.balanceMdInventory.length === 0 ? styles.noBalance : ''}`}>
              {this.balanceMdInventory.length > 0 &&
                <BalanceContainer
                  items={this.balanceMdInventory}
                  onBalanceChange={this.onBalanceChange}
                  type1={'md1'}
                  type2={'md2'}
                />
              }
              {this.balanceMdInventory.length === 0 &&
                <p>kein Lagerbestand</p>
              }
            </div>

          </div>

        </div>

      </Card>
    );
  }

}

export default Product
