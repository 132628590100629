import React from 'react'

import {IProps} from './props'

import styles from './component.module.scss'

export default (props: IProps) => (
    <button
        type={props.type || 'button'}
        onClick={props.onClick}
        disabled={props.disabled}
        className={`btn btn-primary ${styles.loadingButton}${props.className ? ' ' + props.className : ''}${props.isLoading ? ' ' + styles.loads : ''}`}
    >
      <div className={styles.inner}>
        {props.children}
      </div>
    </button>
)

